import _ from 'lodash';
import React, { PropsWithChildren, useState } from 'react';

import { sortAnchorsByIndexNumber } from '../utils/sortAnchorsByIndexNumber';

import { createCtx } from './create';

export interface AnchorType {
  indexNumber: number;
  id: string;
  text: string;
}

type AnchorsProps = {
  visibleAnchors: AnchorType[];
  updateVisibleAnchors: Function;
  allAnchors: AnchorType[];
};

type AnchorProviderProps = PropsWithChildren<AnchorsProps>;

const [useCtx, CtxProvider] = createCtx<AnchorsProps>({
  visibleAnchors: [],
  updateVisibleAnchors: () => {},
  allAnchors: [],
});

const AnchorProvider = ({
  children,
  allAnchors,
}: Omit<AnchorProviderProps, 'updateVisibleAnchors' | 'visibleAnchors'>) => {
  const [_visibleAnchors, updateVisibleAnchors] = useState(
    allAnchors.slice(0, 1),
  );

  const update = (nextAnchors: AnchorType[]) => {
    updateVisibleAnchors(_.uniqBy(sortAnchorsByIndexNumber(nextAnchors), 'id'));
  };

  return (
    <CtxProvider
      value={{
        visibleAnchors: _visibleAnchors,
        updateVisibleAnchors: update,
        allAnchors,
      }}
    >
      {children}
    </CtxProvider>
  );
};

const useVisibleAnchors = () => {
  const context = useCtx();

  return context.visibleAnchors;
};

const useUpdateVisibleAnchors = () => {
  const context = useCtx();

  return context.updateVisibleAnchors;
};

const useAllAnchors = () => {
  const context = useCtx();

  return context.allAnchors;
};

export {
  AnchorProvider,
  useVisibleAnchors,
  useUpdateVisibleAnchors,
  useAllAnchors,
};
