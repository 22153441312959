const { DateTime } = require('luxon');

export const sortByDate = (items: any[], property: string): any[] => {
  return items.sort((a, b) => {
    const aDate = DateTime.fromISO(a[property]);
    const bDate = DateTime.fromISO(b[property]);

    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
  });
};
