import styled from 'styled-components';

import Arrow from '../../../assets/icons/arrow.inline.svg';
import { Color, getColor } from '../../../theme/color';
import {
  ScreenSize,
  getMediaQuery,
  getUnitAsPixels,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Content = styled.div`
  display: grid;
  grid-template-columns: 90% 10%;
  align-items: flex-start;
  grid-column: span 12;
`;

export const Link = styled.a`
  display: grid;
  justify-items: end;
  text-decoration: none;
  color: ${getColor(Color.BLACK)};
  margin-top: ${getUnitAsPixels()};

  & > svg {
    ${getTypography(Type.HEADING_HERO_ICON)}
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-top: ${getUnitAsPixels({ multiplier: 2 })};
  }
`;

export const Text = styled.h1`
  ${getTypography(Type.HEADING_HERO)}
`;

export const ArrowIcon = styled(Arrow)`
  ${Link}:hover & {
    & > path {
      fill: ${getColor(Color.RED)};
    }
  }
`;
