import React from 'react';
import { ThemeProvider } from 'styled-components';

import { useFilters } from '../../../hooks/useFilters';
import { useToggle } from '../../../hooks/useToggle';
import { getTheme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { sortByDate } from '../../../utils/sortDate';
import { Container } from '../../common/container';
import { Filters } from '../../common/filters';
import { ShowMore } from '../../common/showMore';

import { ItemUpdateList } from './itemUpdateList';
import {
  DownloadList,
  Expanded,
  Col,
  ShowMoreContainer,
} from './moduleUpdateList.styles';
import { ModuleUpdateListProps } from './moduleUpdateList.types';

export const ModuleUpdateList = ({
  theme,
  items,
  collapsible,
  hasSpace,
}: ModuleUpdateListProps) => {
  const expandedOver = collapsible ? 8 : items.length;

  const [isShowMore, toggleShowMore] = useToggle(false);
  const { filters, activeFilter, filteredItems, chageFilter } = useFilters(
    'All',
    sortByDate(
      items.map((item) => ({ ...item, year: item.date.split('-')[0] })),
      'date',
    ),
    'number',
    'year',
  );

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container
        spaceTop={
          hasSpace ? VerticalSpacingVariant.SMALL : VerticalSpacingVariant.NONE
        }
        spaceBottom={
          hasSpace
            ? VerticalSpacingVariant.SMALL
            : VerticalSpacingVariant.MEDIUM
        }
      >
        <Col>
          <Filters
            filters={filters}
            activeFilter={activeFilter}
            onChange={chageFilter}
          />
        </Col>

        <DownloadList>
          {filteredItems.slice(0, expandedOver).map((item) => (
            <ItemUpdateList {...item} />
          ))}
          {isShowMore && (
            <Expanded>
              {filteredItems.slice(expandedOver).map((item) => (
                <ItemUpdateList {...item} />
              ))}
            </Expanded>
          )}
          <ShowMoreContainer>
            {filteredItems.length > expandedOver && (
              <ShowMore
                isShowMore={isShowMore}
                toggleShowMore={toggleShowMore}
              />
            )}
          </ShowMoreContainer>
        </DownloadList>
      </Container>
    </ThemeProvider>
  );
};
