import { Link as GatsbyLink } from 'gatsby';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { ThemeProvider } from 'styled-components';

import { parseLinks } from '../../../common/content';
import {
  useVisibleAnchors,
  useUpdateVisibleAnchors,
  useAllAnchors,
  AnchorType,
} from '../../../context/anchor';
import { useOnScreen } from '../../../hooks/useOnScreen';
import { getTheme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';

import { Content, Link, Text, ArrowIcon } from './moduleHeading.styles';
import { ModuleHeadingProps } from './moduleHeading.types';

export const ModuleHeading = ({
  theme,
  text,
  anchor,
  hasDivider,
  link,
  innerPageTitle,
}: ModuleHeadingProps) => {
  const anchorRef: any = useRef<HTMLDivElement>();
  const isVisible: boolean = useOnScreen(anchorRef, '9999px 0px -300px 0px');
  const parsedLinks = link ? parseLinks(link) : [];
  const visibleAnchors = useVisibleAnchors();
  const updateVisibleAnchors = useUpdateVisibleAnchors();
  const allAnchors = useAllAnchors();

  useEffect(() => {
    const currentAnchor = allAnchors.find((a: AnchorType) => a.id === anchor);

    if (isVisible && !!anchor) {
      updateVisibleAnchors([...visibleAnchors, currentAnchor]);
    }

    if (!isVisible && !!anchor) {
      updateVisibleAnchors(_.pullAllBy(visibleAnchors, [currentAnchor], 'id'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container
        spaceBottom={VerticalSpacingVariant.LARGE}
        borderTop={hasDivider}
        innerPageTitle={innerPageTitle}
      >
        <Content>
          <Text id={anchor} ref={anchorRef}>
            {text}
          </Text>
          {parsedLinks.length ? (
            <Link as={GatsbyLink} to={parsedLinks[0].url}>
              <ArrowIcon />
            </Link>
          ) : (
            ''
          )}
        </Content>
      </Container>
    </ThemeProvider>
  );
};
