import styled from 'styled-components';

import { fadeIn } from '../../../theme/animation';
import { getMediaQuery, ScreenSize } from '../../../theme/layout';

export const DownloadList = styled.div`
  grid-column: span 8;

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 5 / span 8;
  }
`;

export const Expanded = styled.div`
  animation: ${fadeIn} 1s ease;
`;

export const Col = styled.div`
  grid-column: span 4;
`;

export const ShowMoreContainer = styled.div`
  display: grid;
  & > div {
    margin-left: calc(25% + 5px);
  }
`;
